import React from 'react'
import { string } from 'prop-types';
import css from './FieldSubDescription.module.css';

const FieldSubDescription = (props) => {
    const { subDescription } = props

    return <>
        {subDescription && subDescription !== "N/A" ? <span className={css.subDescription}>{subDescription}</span> : null}
    </>
}
FieldSubDescription.defaultProps = {
    subDescription: null
};

FieldSubDescription.propTypes = {
    subDescription: string,
};


export default FieldSubDescription