import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import { NamedLink } from '../../../components';
import css from './SmallPrint.module.css';

export const SmallPrint = props => {
  const { authorId, rootClassName, onlyLink } = props;

  const profileLink = <NamedLink
    className={rootClassName ? rootClassName : css.setLowerCase}
    name="ProfilePage"
    params={{ id: authorId }}
  >
    <FormattedMessage id="OrderPanel.infoBox.profileLink" />
  </NamedLink>

  if (onlyLink) {
    return profileLink;
  } else {
    return <div style={{ display: 'flex' }}>
      {authorId ? <p className={css.finePrint}>
        <FormattedMessage id={'OrderPanel.rent.smallPrint'} />{' '}
        {profileLink}
      </p> : null}
    </div>
  };
};

export default SmallPrint;
