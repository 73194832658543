import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { LinkTabNavHorizontal } from '../../components';
import { ACCOUNT_SETTINGS_PAGES } from '../../routing/routeConfiguration';
import { FormattedMessage } from '../../util/reactIntl';

import css from './UserNav.module.css';

const UserNav = props => {
  const { className, rootClassName, currentPage, isHomePage } = props;
  const classes = classNames(rootClassName || css.root, className);

  const tabs = [
    {
      text: <FormattedMessage id="UserNav.yourListings" />,
      selected: currentPage === 'ManageListingsPage',
      linkProps: {
        name: 'ManageListingsPage',
      },
    },
    {
      text: <FormattedMessage id="UserNav.profileSettings" />,
      selected: currentPage === 'ProfileSettingsPage',
      disabled: false,
      linkProps: {
        name: 'ProfileSettingsPage',
      },
    },
    {
      text: <FormattedMessage id="UserNav.accountSettings" />,
      selected: ACCOUNT_SETTINGS_PAGES.includes(currentPage),
      disabled: false,
      linkProps: {
        name: 'ContactDetailsPage',
      },
    },
  ];
  const [scrollingDown, setScrollingDown] = useState(false);
  const [prevScrollPosition, setPrevScrollPosition] = useState(0);

  useEffect(() => {
    const maxWindowHeight =
      Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
      ) - window.innerHeight;

    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      // Prevent Safari scroll overflow
      if (scrollPosition > 0 && scrollPosition < maxWindowHeight) {
        // Set scrollingDown to true when scrolling down
        setScrollingDown(scrollPosition > prevScrollPosition);
        setPrevScrollPosition(scrollPosition);
      }
      // Some of the page default window height can't get it so calculate it if is empty
      if (maxWindowHeight === 0) {
        setScrollingDown(scrollPosition > prevScrollPosition);
        setPrevScrollPosition(scrollPosition);
      }
    };

    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPosition]);

  const navbarTransform = !isHomePage
    ? {
        transform: `translateY(${scrollingDown ? '-130%' : '0'})`, // Hide when scrolling down, show when scrolling up
        transition: 'transform 0.7s ease 0s',
        zIndex: scrollingDown ? 20 : 0,
        position: 'relative',
      }
    : {};

  return (
    <div style={navbarTransform}>
      <LinkTabNavHorizontal
        className={classes}
        tabRootClassName={css.tab}
        tabs={tabs}
        skin="dark"
      />
    </div>
  );
};

UserNav.defaultProps = {
  className: null,
  rootClassName: null,
  isHomePage: true,
};

const { string } = PropTypes;

UserNav.propTypes = {
  className: string,
  rootClassName: string,
  currentPage: string.isRequired,
};

export default UserNav;
