import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import bankIcon from './bankIcon.svg'

import css from './IconBank.module.css';

const IconBank = props => {
    const { className, rootClassName } = props;
    const classes = classNames(rootClassName || css.root, className);

    return (<div className={classes}>
        <img src={bankIcon} className={css.icon}/>
    </div>
    );
};

const { string } = PropTypes;

IconBank.defaultProps = {
    className: null,
    rootClassName: null,
};

IconBank.propTypes = {
    className: string,
    rootClassName: string,
};

export default IconBank;
