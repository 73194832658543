import React from 'react';
import { NamedLink } from '../../../components';
import { ReactComponent as NewCartIcon } from './newCartIcon.svg';
import css from './CartComponent.module.css';

const CartComponent = (props) => {
    const { cartItemsCount } = props;

    const isCartEmpty = cartItemsCount == 0;

    return <div className={css.root}>
        <NamedLink name="CartPage" >
            {isCartEmpty ? null : <div className={css.counter}>
                {cartItemsCount}
            </div>}
            <NewCartIcon />
        </NamedLink>
    </div>
};

export default CartComponent;
