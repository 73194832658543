import {
    updateTxMetadata,
    getCartsInfo,
    getLineItems,
    cartInquiry,
} from "../../util/api";
import { createSlug } from '../../util/urlHelpers';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import { initializeCardPaymentData } from "../../ducks/stripe.duck";
import { types as sdkTypes } from '../../util/sdkLoader';

const { UUID } = sdkTypes;

export const SET_INITIAL_STATE = 'app/CartPage/SET_INITIAL_STATE';

export const GET_CARTS_REQUEST = 'app/CartPage/GET_CARTS_REQUEST';
export const GET_CARTS_SUCCESS = 'app/CartPage/GET_CARTS_SUCCESS';
export const GET_CARTS_ERROR = 'app/CartPage/GET_CARTS_ERROR';

export const GET_LINEITEMS_REQUEST = 'app/CartPage/GET_LINEITEMS_REQUEST';
export const GET_LINEITEMS_SUCCESS = 'app/CartPage/GET_LINEITEMS_SUCCESS';
export const GET_LINEITEMS_ERROR = 'app/CartPage/GET_LINEITEMS_ERROR';

export const GENERAL_REQUEST = 'app/CartPage/GENERAL_REQUEST';
export const GENERAL_SUCCESS = 'app/CartPage/GENERAL_SUCCESS';
export const GENERAL_ERROR = 'app/CartPage/GENERAL_ERROR';

const initialState = {
    getCartsError: null,
    getCartsInProgress: false,
    carts: [],
    getLineItemsError: null,
    getLineItemsInProgress: false,
    cartsLineItems: [],
    generalInProgress: false,
    generalError: null,
};

export default function cartPageReducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case SET_INITIAL_STATE:
            return { ...initialState };

        case GET_CARTS_REQUEST:
            return { ...state, getCartsError: null, getCartsInProgress: true };
        case GET_CARTS_SUCCESS:
            return { ...state, getCartsInProgress: false, carts: payload };;
        case GET_CARTS_ERROR:
            return { ...state, getCartsInProgress: false, getCartsError: payload };

        case GET_LINEITEMS_REQUEST:
            return { ...state, getLineItemsError: null, getLineItemsInProgress: true };
        case GET_LINEITEMS_SUCCESS:
            return { ...state, getLineItemsInProgress: false, cartsLineItems: payload };;
        case GET_LINEITEMS_ERROR:
            return { ...state, getLineItemsInProgress: false, getLineItemsError: payload };

        case GENERAL_REQUEST:
            return { ...state, generalError: null, generalInProgress: true };
        case GENERAL_SUCCESS:
            return { ...state, generalInProgress: false };;
        case GENERAL_ERROR:
            return { ...state, generalInProgress: false, generalError: payload };

        default:
            return state;
    }
};

export const setInitialState = () => ({
    type: SET_INITIAL_STATE,
});

export const getCartsRequest = () => ({
    type: GET_CARTS_REQUEST,
});

export const getCartsSuccess = (carts) => ({
    type: GET_CARTS_SUCCESS,
    payload: carts,
});

export const getCartsError = e => ({
    type: GET_CARTS_ERROR,
    error: true,
    payload: e,
});

export const getLineItemsRequest = () => ({
    type: GET_LINEITEMS_REQUEST,
});

export const getLineItemsSuccess = (cartsLineItems) => ({
    type: GET_LINEITEMS_SUCCESS,
    payload: cartsLineItems,
});

export const getLineItemsError = e => ({
    type: GET_LINEITEMS_ERROR,
    error: true,
    payload: e,
});

export const generalRequest = () => ({
    type: GENERAL_REQUEST,
});

export const generalSuccess = () => ({
    type: GENERAL_SUCCESS,
});

export const generalError = e => ({
    type: GENERAL_ERROR,
    error: true,
    payload: e,
});

export const getCarts = ({ txCart, isProvider, isConfirmed }) => (dispatch, getState, sdk) => {
    dispatch(getCartsRequest());

    return getCartsInfo({ txCart })
        .then(response => {
            dispatch(getCartsSuccess(response.carts));
            if (!isConfirmed) {
                dispatch(getCartsLineItems({
                    carts: response.carts,
                    txCart,
                    isProvider,
                }));
            };
            return response;
        })
        .catch(() => dispatch(getCartsError("Failed to get carts")));
};

export const getCartsLineItems = ({ carts, txCart, isProvider }) => (dispatch, getState, sdk) => {
    dispatch(getLineItemsRequest());

    return getLineItems({
        carts: carts.map(c => {
            return {
                listings: c.listings.map(l => l.id.uuid),
                authorId: c.author.id.uuid,
                type: c.type,
            };
        }),
        txCart,
        isProvider,
    })
        .then(response => {
            dispatch(getLineItemsSuccess(response.cartsLineItems));
            return response;
        })
        .catch(() => dispatch(getLineItemsError("Failed to get line items")));
};

export const removeListingFromCart = params => (dispatch, getState, sdk) => {
    dispatch(getCartsRequest());

    const { listingId } = params;

    const userCart = getState().user.currentUser.attributes.profile.publicData.cart || [];
    const newCart = userCart.filter(i => i.listingId !== listingId);

    return dispatch(updateProfile({
        publicData: { cart: newCart }
    }))
        .then(() => {
            dispatch(getCarts({ txCart: null }));
        })
        .catch(() => {
            dispatch(getCartsError("Failed to remove listing from cart."));
        });
};

export const removeListingFromTxCart = params => (dispatch, getState, sdk) => {
    dispatch(getCartsRequest());

    const { listingId, txCart = [], txId } = params;
    const newCart = txCart.filter(i => i.listingId !== listingId);

    return updateTxMetadata({
        metadata: { cart: newCart },
        txId,
    })
        .then((res) => {
            const { success } = res;
            if (success) {
                dispatch(getCarts({ txCart: newCart }));
            } else {
                dispatch(getCartsError("Failed to remove listing from transaction."));
            };
        });
};

export const inquireAboutCart = params => (dispatch, getState, sdk) => {
    dispatch(generalRequest());

    return cartInquiry(params)
        .then((res) => {
            const { success, transactionId } = res;
            if (success) {
                dispatch(generalSuccess());
                window.open(`/order/${transactionId.uuid}`, "_self");
            } else {
                dispatch(generalError("Failed to create inquiry."));
            };
        })
        .catch(() => {
            dispatch(generalError("Failed to create inquiry."));
        });
};

export const handleCartSubmit = params => (dispatch, getState, sdk) => {
    dispatch(generalRequest());

    const { selectedCart, history, routeConfiguration } = params;
    const [mainListing, ...otherListings] = selectedCart;

    const listingId = new UUID(mainListing.listingId);
    const isRent = mainListing.type == 'rent';

    return sdk.listings.show({ id: listingId, include: ['author'], }).then(res => {
        const listing = res.data.data;
        const listingAuthor = res.data.included.find(i => i.type == 'user');
        listing.author = listingAuthor;

        const bookingDates = isRent ? {
            startDate: new Date(mainListing.startDate),
            endDate: new Date(mainListing.endDate),
        } : null;
        const quantityRaw = isRent ? null : 1;
        const deliveryMethod = isRent ? null : 'pickup';

        const bookingMaybe = bookingDates ? {
            bookingDates: {
                bookingStart: bookingDates.startDate,
                bookingEnd: bookingDates.endDate,
            },
        } : {};
        const quantity = Number.parseInt(quantityRaw, 10);
        const quantityMaybe = Number.isInteger(quantity) ? { quantity } : {};
        const deliveryMethodMaybe = deliveryMethod ? { deliveryMethod } : {};

        const initialValues = {
            listing,
            orderData: {
                ...bookingMaybe,
                ...quantityMaybe,
                ...deliveryMethodMaybe,
            },
            confirmPaymentError: null,
        };

        const { setInitialValues } = findRouteByRouteName('CheckoutPage', routeConfiguration);
        dispatch(setInitialValues(initialValues));
        dispatch(initializeCardPaymentData());

        localStorage.setItem("CheckedCart", JSON.stringify(selectedCart));
        dispatch(generalSuccess());

        return history.push(
            createResourceLocatorString(
                'CheckoutPage',
                routeConfiguration,
                { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
                {}
            )
        );
    });
};

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
    dispatch(setInitialState());

    return Promise.all([
        dispatch(getCarts({ txCart: null })),
    ]);
};
